<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        newstrend {{perf}}
        <DataTable :value="newstrends" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
        :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
        :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        selectionMode="single"
        v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
          <Column field="id" header="No" :sortable="true"></Column>
          <Column field="trend_name" header="感情" :sortable="true">
            <template #body="slotProps">
              <span :class="'sentiment-badge status-' + slotProps.data.trend_name.toLowerCase().slice(-1)">{{slotProps.data.trend_name.slice(-1)}}</span>
            </template>          
          </Column>     
          <Column field="meigara_cnt" header="銘柄数" :sortable="true"></Column>    
          <Column field="trend_name" header="トレンド名" filterField="trend_name" filterMatchMode="contains" ref="trend_name" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
            </template> 
          </Column>                     
          <Column field="trend_date" header="トレンド日付" filterField="trend_date" filterMatchMode="contains" ref="trend_date" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
            </template>
          </Column>          
          <Column field="trend_timestamp" header="トレンド時刻" :sortable="true"></Column>
          <Column field="meigara_name_list" header="銘柄リスト" filterField="meigara_cd_list" filterMatchMode="contains" ref="meigara_name_list" :sortable="true" :style="{'min-width': '10rem'}">
            <template #filter="{filterModel,filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import NewstrendService from '@/service/NewstrendService';

export default {
  data() {
    return {
      loading: false,
      totalRecords: 0,
      newstrends: null,
      lazyParams: {},
      filters1: {},
      filters: {
        'trend_date': {value: '', matchMode: 'contains'},
        'trend_name': {value: '', matchMode: 'contains'},
        'meigara_cd_list': {value: '', matchMode: 'contains'},
      }
    }
  },
  newstrendService: null,
  created() {
    this.newstrendService = new NewstrendService();
  },
  mounted() {
    this.loading = true;

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: "id",
      sortOrder: "-1",
      filters: this.filters,
      perf: null,
    };

    this.loadLazyData();
  },
  methods: {
    loadLazyData() {
      this.loading = true;

    setTimeout(() => {
      this.newstrendService.getAll(
        {dt_params: JSON.stringify( this.lazyParams )})
          .then(data => {
              this.newstrends = data.payload.data;
              this.totalRecords = data.payload.total;
              this.loading = false;
              this.perf = data.perf;
        }
      );
    }, Math.random() * 1000 + 250);
    },
    onPage(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/badges.scss';
</style>